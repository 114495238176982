/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import axios from "axios";
import { useNavigate, NavLink, Link } from "react-router-dom";
import {
  FaSignOutAlt,
  FaTachometerAlt,
  FaUserAlt,
  FaUserLock,
} from "react-icons/fa";
import { API_URL_AUTH } from "../../config/config";
import logoPsi from "../../assets/images/logopsisimku-web.png";

const Navbar = () => {
  const navigate = useNavigate();

  const [menuActive, setMenuActive] = useState(false);
  const mActive = menuActive ? "is-active" : "";

  const Logout = async () => {
    try {
      await axios.delete(`${API_URL_AUTH}/admin/logout`);
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  const menuShow = () => {
    if (menuActive === false) {
      setMenuActive(true);
    } else {
      setMenuActive(false);
    }
  };

  return (
    <>
      <nav
        className="navbar is-white box p-2 is-fixed-top"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <img src={logoPsi} width="112" height="28" alt="logo" />
            </Link>

            <a
              onClick={menuShow}
              role="button"
              className={`navbar-burger burger ${mActive}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div id="navbarBasicExample" className={`navbar-menu ${mActive}`}>
            <div className="navbar-start">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "navbar-item is-size-7 is-active"
                    : "navbar-item is-size-7"
                }
              >
                <span className="icon">
                  <FaTachometerAlt />
                </span>
                <span>HOME</span>
              </NavLink>

              <NavLink
                to="profile"
                className={({ isActive }) =>
                  isActive
                    ? "navbar-item is-size-7 is-active"
                    : "navbar-item is-size-7"
                }
              >
                <span className="icon">
                  <FaUserAlt />
                </span>
                <span>ACCOUNT</span>
              </NavLink>
              <NavLink
                to="user"
                className={({ isActive }) =>
                  isActive
                    ? "navbar-item is-size-7 is-active"
                    : "navbar-item is-size-7"
                }
              >
                <span className="icon">
                  <FaUserLock />
                </span>
                <span>USER ADMIN</span>
              </NavLink>
            </div>

            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <button
                    onClick={Logout}
                    className="button is-warning is-small is-rounded"
                  >
                    <span className="icon">
                      <FaSignOutAlt />
                    </span>
                    <span>LOG OUT</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { FaSync, FaPlus, FaSearch, FaTrash, FaPencilAlt } from "react-icons/fa";
import { API_URL_AUTH } from "../../config/config";

const PetugasPolresIndex = () => {
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const navigate = useNavigate();

  const [petugas, setPetugas] = useState([]);
  const [page, setPage] = useState(0);
  /* eslint-disable-next-line */
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [field, setField] = useState("namaLengkap");
  const [fieldQuery, setFieldQuery] = useState("namaLengkap");
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    refreshToken();
    getAllPetugas();
  }, [page, keyword, fieldQuery]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getAllPetugas = async () => {
    await axiosJWT
      .get(
        `${API_URL_AUTH}/admin/user-polres?field_query=${fieldQuery}&search_query=${keyword}&page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setPetugas(response.data.data);
        setPage(response.data.page);
        setPages(response.data.totalPage);
        setRows(response.data.totalRows);
      });
  };

  const deleteOutlet = async (id) => {
    Swal.fire({
      title: "Konfirmasi Hapus?",
      text: "Yakin ingin menghapus data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, hapus data!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axiosJWT
          .delete(`${API_URL_AUTH}/admin/petugas/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            getAllPetugas();
            Swal.fire("Deleted!", response.data.message, "success");
          })
          .catch((error) => {
            Swal.fire("Deleted!", error.response.data.message, "error");
          });
      }
    });
  };

  const changePage = ({ selected }) => {
    setPage(selected);
    if (selected === 9) {
      setMsg(
        "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
      );
    } else {
      setMsg("");
    }
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
    setFieldQuery(field);
  };

  return (
    <>
      <nav className="breadcrumb is-small" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="is-active">
            <Link href="#" aria-current="page">
              Data Petugas Polres
            </Link>
          </li>
        </ul>
      </nav>
      <div className="columns is-vcentered is-desktop">
        <div className="column is-4">
          <div className="buttons">
            <Link
              to="add"
              className="button is-small is-info is-light is-rounded"
            >
              <span className="icon">
                <FaPlus />
              </span>
              <span className="is-hidden-mobile">Tambah Petugas Polres</span>
            </Link>
            <a
              href="/petugas-polres"
              className="button is-grey is-small is-rounded is-light"
            >
              <span className="icon">
                <FaSync />
              </span>
              <span className="is-hidden-mobile">Refresh</span>
            </a>
          </div>
        </div>
        <div className="column">
          <form
            onSubmit={searchData}
            className="is-desktop columns is-variable is-1-desktop"
          >
            <div className="column is-3">
              <div className="field">
                <label className="label is-small">Search by</label>
                <div className="control">
                  <span className="select is-small is-fullwidth">
                    <select
                      value={field}
                      onChange={(e) => setField(e.target.value)}
                    >
                      <option value="namaLengkap">Nama Petugas Polres</option>
                    </select>
                  </span>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label is-small">Keyword</label>
                <div className="control">
                  <input
                    type="text"
                    className="input is-small"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Find something here..."
                  />
                </div>
              </div>
            </div>
            <div className="column is-2">
              <div className="field">
                <label className="label is-small">&nbsp;</label>
                <div className="control">
                  <button
                    type="submit"
                    className="button is-grey is-small is-rounded is-light"
                  >
                    <span className="icon">
                      <FaSearch />
                    </span>
                    <span className="is-hidden-mobile">Cari</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {petugas ? (
        <>
          <div className="table-container">
            <table className="table is-striped is-bordered is-fullwidth is-hoverable is-size-7">
              <thead className="table-header">
                <tr>
                  <th>No.</th>
                  <th>Nama Lengkap</th>
                  <th>Kode Polres</th>
                  <th>Kesatuan</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th colSpan="2">Aksi</th>
                </tr>
              </thead>
              <tbody>
                {petugas.map((petugas, index) => (
                  <tr key={petugas.id}>
                    <td>{page * limit + index + 1}</td>
                    <td>
                      <strong>{petugas.namaLengkap}</strong>
                    </td>
                    <td>
                      <strong>{petugas.polresKode}</strong>
                    </td>
                    <td>
                      <strong>{petugas.kesatuan}</strong>
                    </td>
                    <td>{petugas.createdAt}</td>
                    <td>{petugas.updatedAt}</td>
                    <td>
                      <Link
                        key={`edit-${index}`}
                        to={`${petugas.id}`}
                        className="button is-link is-light is-rounded is-small"
                      >
                        <span className="icon">
                          <FaPencilAlt />
                        </span>
                      </Link>
                    </td>
                    {/* <td>
                      <button
                        key={`del-${index}`}
                        onClick={() => deleteOutlet(petugas.id)}
                        className="button is-small is-rounded is-danger is-light"
                      >
                        <span className="icon">
                          <FaTrash />
                        </span>
                      </button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <p className="has-text-centered has-text-danger">{msg}</p>
          <div className="columns">
            <div className="column">
              <span className="is-size-7">
                Total Rows: {rows} Page: {rows ? page + 1 : 0} of {pages}
              </span>
            </div>
            <div className="column">
              <nav
                className="pagination is-rounded is-small is-right"
                key={rows}
                role="navigation"
                aria-label="pagination"
              >
                <ReactPaginate
                  previousLabel={"Prev"}
                  nextLabel={"Next"}
                  pageCount={Math.min(10, pages)}
                  onPageChange={changePage}
                  containerClassName={"pagination-list"}
                  pageLinkClassName={"pagination-link"}
                  previousLinkClassName={"pagination-previous"}
                  nextLinkClassName={"pagination-next"}
                  activeLinkClassName={"pagination-link is-current"}
                  disabledLinkClassName={"pagination-link is-disabled"}
                />
              </nav>
            </div>
          </div>
        </>
      ) : (
        <div className="is-fullwidth has-text-centered mt-5">
          <figure className="image is-2by2 is-inline-block">
            <img
              src="https://plan.literasikitaindonesia.com/kasir/no-data-found.png"
              alt="logo cart"
            />
          </figure>
          <h4>Data tidak ditemukan</h4>
        </div>
      )}
    </>
  );
};

export default PetugasPolresIndex;

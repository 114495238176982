/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import {
  FaSave,
  FaTimes,
  FaMinusCircle,
  FaCheck,
  FaCalendar,
} from "react-icons/fa";
import { API_URL_AUTH } from "../../config/config";
import { NikParser } from "../../libs/nikParser";

const PetugasAdd = () => {
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [errors, setErrors] = useState([]);

  const [namaLengkap, setNamaLengkap] = useState("");
  const [tmpLahir, setTmpLahir] = useState("");
  const [tglLahir, setTglLahir] = useState("");
  const [nik, setNik] = useState("");
  const [outletId, setOutletId] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [outlets, setOutlets] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    refreshToken();
    allOutlet();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const allOutlet = async () => {
    await axiosJWT
      .get(`${API_URL_AUTH}/admin/all-outlet`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setOutlets(response.data.data);
      });
  };

  const simpanPetugas = async (e) => {
    e.preventDefault();

    await axiosJWT
      .post(
        `${API_URL_AUTH}/admin/petugas`,
        {
          namaLengkap: namaLengkap,
          tmpLahir: tmpLahir,
          tglLahir: tglLahir,
          nik: nik,
          outletId: outletId,
          username: username,
          password: password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setNamaLengkap("");
        setTmpLahir("");
        setTglLahir("");
        setNik("");
        setOutletId("");
        setUsername("");
        setPassword("");
        setErrors([]);

        Swal.fire({
          title: "Sukses!",
          text: response.data.message,
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "OK, Kembali ke menu!",
          cancelButtonText: "Tutup, Input data lagi!",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        if (error.response.status === 422) {
          console.log(error.response.data.errors);
          setErrors(error.response.data.errors);
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: error.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        }
      });
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const checkErrorInput = (errors, param) => {
    const found = errors.find((error) => error.param === param);
    if (found) {
      return found;
    } else {
      return false;
    }
  };

  const getNikParser = () => {
    const nikPar = NikParser(nik);

    if (nikPar.isValid()) {
      // const tglLhr = new Date(nikPar.lahir());
      const month = nikPar.lahir().getMonth() + 1;
      const dateLhr = ("0" + nikPar.lahir().getDate()).slice(-2);
      const mnthLhr = ("0" + month).slice(-2);
      const tglFull =
        nikPar.lahir().getFullYear() + "-" + mnthLhr + "-" + dateLhr;
      // console.log(tglFull);
      setTglLahir(tglFull);
    }
  };

  return (
    <>
      <nav className="breadcrumb is-small" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/petugas">Data Petugas</Link>
          </li>
          <li className="is-active">
            <Link href="#" aria-current="page">
              Tambah Petugas
            </Link>
          </li>
        </ul>
      </nav>
      <div className="columns">
        <div className="column is-three-fifths">
          <form onSubmit={simpanPetugas}>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Nama Lengkap</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-right">
                    <input
                      className="input is-small"
                      type="text"
                      value={namaLengkap}
                      onChange={(e) => setNamaLengkap(e.target.value)}
                      placeholder="Nama lengkap petugas"
                    />
                    {checkErrorInput(errors, "namaLengkap") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </p>
                  <p className="help">Nama lengkap petugas</p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">NIK</label>
              </div>
              <div className="field-body">
                <div className="field has-addons">
                  <p className="control has-icons-right">
                    <input
                      className="input is-small"
                      type="text"
                      value={nik}
                      onChange={(e) => setNik(e.target.value)}
                      placeholder="NIK"
                    />
                    {checkErrorInput(errors, "nik") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </p>
                  <p className="control is-expanded">
                    <button
                      onClick={() => getNikParser()}
                      className="button is-small is-link"
                      type="button"
                      placeholder="Amount of money"
                    >
                      Cari Nik
                    </button>
                  </p>
                </div>

                <div className="field">&nbsp;</div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Tempat, Tgl. Lahir</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-right">
                    <input
                      className="input is-small"
                      type="text"
                      value={tmpLahir}
                      onChange={(e) => setTmpLahir(e.target.value)}
                      placeholder="Tempat Lahir"
                    />
                    {checkErrorInput(errors, "tmpLahir") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </p>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left has-icons-right">
                    <input
                      className="input is-small"
                      type="date"
                      value={tglLahir}
                      onChange={(e) => setTglLahir(e.target.value)}
                      placeholder="tanggal lahir"
                    />
                    <span className="icon is-small is-left">
                      <FaCalendar />
                    </span>
                    {checkErrorInput(errors, "tglLahir") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Username / Password</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-right">
                    <input
                      className="input is-small"
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Username"
                    />
                    {checkErrorInput(errors, "username") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </p>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-right">
                    <input
                      className="input is-small"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="***"
                    />
                    {checkErrorInput(errors, "password") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Outlet</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control has-icons-right">
                    <select
                      value={outletId}
                      onChange={(e) => setOutletId(e.target.value)}
                      className="select is-small is-fullwidth"
                    >
                      <option value="">- Pilih Outlet -</option>
                      {outlets.map((outlet, index) => (
                        <option key={`out-${index}`} value={outlet.id}>
                          {outlet.namaOutlet}
                        </option>
                      ))}
                    </select>
                    {checkErrorInput(errors, "outletId") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </div>
                  <p className="help">Pilih outlet tempat bekerja petugas</p>
                </div>
                <div className="field">&nbsp;</div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label">&nbsp;</div>
              <div className="field-body">
                <div className="field is-grouped">
                  <div className="control">
                    <button
                      type="submit"
                      className="button is-link is-small is-rounded"
                    >
                      <span className="icon">
                        <FaSave />
                      </span>
                      <span>Simpan Petugas</span>
                    </button>
                  </div>
                  <div className="control">
                    <button
                      type="button"
                      onClick={() => navigate(-1)}
                      className="button is-danger is-light is-small is-rounded"
                    >
                      <span className="icon">
                        <FaTimes />
                      </span>
                      <span>Batalkan</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <hr />
          {errors.map((error, index) => (
            <p key={`error-${index}`} className="has-text-danger is-size-7">
              <FaMinusCircle /> {error.msg}
            </p>
          ))}
        </div>
      </div>
    </>
  );
};

export default PetugasAdd;

import React from "react";
import { IconContext } from "react-icons";
import { FaUsersCog } from "react-icons/fa";
import { Outlet } from "react-router-dom";

const PetugasRoot = () => {
  return (
    <section className="section is-fullwidth">
      <div className="container">
        <div className="labelPage2">
          <div className="iconLabel2">
            <IconContext.Provider
              value={{
                className: "icon is-large has-text-white",
              }}
            >
              <FaUsersCog />
            </IconContext.Provider>
          </div>
          <div className="vertical-center">
            <h1 className="title ml-6 is-6 has-text-white">
              DATA PETUGAS POLRES
            </h1>
            <p className="subtitle is-7 ml-6 has-text-white">
              Data semua petugas POLRES
            </p>
          </div>
        </div>
        <div className="boxCard">
          <Outlet />
        </div>
      </div>
    </section>
  );
};

export default PetugasRoot;

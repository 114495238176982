/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { useNavigate, Link, useParams } from "react-router-dom";
import {
  FaSave,
  FaTimes,
  FaMinusCircle,
  FaCheck,
  FaCalendar,
} from "react-icons/fa";
import { API_URL_AUTH } from "../../config/config";

const PetgasusEdit = () => {
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [errors, setErrors] = useState([]);

  const { id } = useParams();
  const [namaLengkap, setNamaLengkap] = useState("");
  const [tmpLahir, setTmpLahir] = useState("");
  const [tglLahir, setTglLahir] = useState("");
  const [nik, setNik] = useState("");
  const [outletId, setOutletId] = useState("");

  const [outlets, setOutlets] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    refreshToken();
    allOutlet();
    getPetugasById();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const getPetugasById = async () => {
    await axiosJWT
      .get(`${API_URL_AUTH}/admin/petgasus/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setNamaLengkap(response.data.data.namaLengkap);
        setTmpLahir(response.data.data.tmpLahir);
        setTglLahir(response.data.data.tglLahir);
        setNik(response.data.data.nik);
        setOutletId(response.data.data.outletId);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

  const allOutlet = async () => {
    await axiosJWT
      .get(`${API_URL_AUTH}/admin/all-outlet`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setOutlets(response.data.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const updatePetugas = async (e) => {
    e.preventDefault();

    await axiosJWT
      .put(
        `${API_URL_AUTH}/admin/petgasus/${id}`,
        {
          namaLengkap: namaLengkap,
          tmpLahir: tmpLahir,
          tglLahir: tglLahir,
          nik: nik,
          outletId: outletId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setNamaLengkap("");
        setTmpLahir("");
        setTglLahir("");
        setNik("");
        setOutletId("");
        setErrors([]);

        Swal.fire({
          title: "Sukses!",
          text: response.data.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "OK, Kembali ke menu!",
          cancelButtonText: "Tutup, Input data lagi!",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        if (error.response.status === 422) {
          console.log(error.response.data.errors);
          setErrors(error.response.data.errors);
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: error.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        }
      });
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const checkErrorInput = (errors, param) => {
    const found = errors.find((error) => error.param === param);
    if (found) {
      return found;
    } else {
      return false;
    }
  };

  return (
    <>
      <nav className="breadcrumb is-small" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/petgasus">Data Petugas Khusus</Link>
          </li>
          <li className="is-active">
            <Link href="#" aria-current="page">
              Edit Petugas Khusus
            </Link>
          </li>
        </ul>
      </nav>
      <div className="columns">
        <div className="column is-three-fifths">
          <form onSubmit={updatePetugas}>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Nama Lengkap</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-right">
                    <input
                      className="input is-small"
                      type="text"
                      value={namaLengkap}
                      onChange={(e) => setNamaLengkap(e.target.value)}
                      placeholder="Nama lengkap petugas"
                    />
                    {checkErrorInput(errors, "namaLengkap") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </p>
                  <p className="help">Nama lengkap petugas</p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">NIK</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control has-icons-right">
                    <input
                      className="input is-small"
                      type="text"
                      value={nik}
                      onChange={(e) => setNik(e.target.value)}
                      placeholder="NIK"
                    />
                    {checkErrorInput(errors, "nik") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </p>
                  <p className="help">Nomor Induk Kependudukan</p>
                </div>
                <div className="field">&nbsp;</div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Tempat, Tgl. Lahir</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-right">
                    <input
                      className="input is-small"
                      type="text"
                      value={tmpLahir}
                      onChange={(e) => setTmpLahir(e.target.value)}
                      placeholder="Tempat Lahir"
                    />
                    {checkErrorInput(errors, "tmpLahir") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </p>
                </div>
                <div className="field">
                  <p className="control is-expanded has-icons-left has-icons-right">
                    <input
                      className="input is-small"
                      type="date"
                      value={tglLahir}
                      onChange={(e) => setTglLahir(e.target.value)}
                      placeholder="tanggal lahir"
                    />
                    <span className="icon is-small is-left">
                      <FaCalendar />
                    </span>
                    {checkErrorInput(errors, "tglLahir") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Outlet</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control has-icons-right">
                    <select
                      value={outletId}
                      onChange={(e) => setOutletId(e.target.value)}
                      className="select is-small is-fullwidth"
                    >
                      <option value="">- Pilih Outlet -</option>
                      {outlets.map((outlet, index) => (
                        <option key={`out-${index}`} value={outlet.id}>
                          {outlet.namaOutlet}
                        </option>
                      ))}
                    </select>
                    {checkErrorInput(errors, "outletId") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </div>
                  <p className="help">Pilih outlet tempat bekerja petugas</p>
                </div>
                <div className="field">&nbsp;</div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label">&nbsp;</div>
              <div className="field-body">
                <div className="field is-grouped">
                  <div className="control">
                    <button
                      type="submit"
                      className="button is-link is-small is-rounded"
                    >
                      <span className="icon">
                        <FaSave />
                      </span>
                      <span>Simpan Petugas</span>
                    </button>
                  </div>
                  <div className="control">
                    <button
                      type="button"
                      onClick={() => navigate(-1)}
                      className="button is-danger is-light is-small is-rounded"
                    >
                      <span className="icon">
                        <FaTimes />
                      </span>
                      <span>Batalkan</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <hr />
          {errors.map((error, index) => (
            <p key={`error-${index}`} className="has-text-danger is-size-7">
              <FaMinusCircle /> {error.msg}
            </p>
          ))}
        </div>
      </div>
    </>
  );
};

export default PetgasusEdit;
